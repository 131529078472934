<template>
  <v-container>
    <v-card>
      <v-toolbar>
        <v-toolbar-title v-if="workstudyList.length === 0">Class Schedule</v-toolbar-title>
        <v-menu v-else bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text>
              {{ selectedScheduleTitle }}
              <v-icon right>fal fa-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item :disabled="selectedSchedule === 'student'" @click="setSelectedSchedule('student')">
              <v-list-item-title>Student Class Schedule</v-list-item-title>
            </v-list-item>
            <v-list-item v-for="({ code, title, people }, index) in workstudyList" :key="'workstudy-' + index" :disabled="selectedSchedule === code || people.length === 0" @click="setSelectedSchedule(code)">
              <v-list-item-content>
                <v-list-item-title>{{ title }} Schedule</v-list-item-title>
                <v-list-item-subtitle>{{ people.length }} Student{{ people.length === 1 ? '' : 's' }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-autocomplete v-if="isEmployee && selectedSchedule === 'student'" v-model="selectedStudent" :items="students" :search-input.sync="studentSearch" :filter="() => true" label="Student Search" hide-details>
          <template v-slot:append-outer>
            <v-tooltip v-if="selectedStudent" top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon small @click="clearStudent()" style="margin-top: -4px">
                  <v-icon>fal fa-times-circle</v-icon>
                </v-btn>
              </template>
              <span>Clear student</span>
            </v-tooltip>
          </template>
        </v-autocomplete>
        <v-spacer></v-spacer>
        <v-menu v-if="selectedSchedule === 'student' && termOptions.length > 0" bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :disabled="termOptions.length <= 1" text>
              {{ termText }}
              <v-icon right>fal fa-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="{ text, value, classes } in termOptions" :key="'term-' + value" @click="term = value">
              <v-list-item-content>
                <v-list-item-title>{{ text }}</v-list-item-title>
                <v-list-item-subtitle>{{ classes }} Classes</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-card-text v-if="noClasses">
        <p>It looks like {{ isEmployee ? 'this student is' : 'you are' }} not registered for any classes for the selected term.</p>
        <p v-if="!isEmployee">If you recently registered for your classes in Banner then please allow a few hours for your schedule to feed to the Portal.</p>
        <p v-if="!isEmployee">If you are a new student who recently submitted your pre-registration form, then you may need to wait a few days for your schedule to feed to the Portal.</p>
      </v-card-text>
      <template v-else-if="selectedSchedule === 'student'">
        <v-tabs v-model="tab">
          <v-tab>
            <v-icon v-if="!isSmall" left>fas fa-list</v-icon>
            List View
          </v-tab>
          <v-tab>
            <v-icon v-if="!isSmall" left>fas fa-calendar</v-icon>
            Calendar View
          </v-tab>
          <v-spacer></v-spacer>
          <v-btn v-if="!isSmall" href="https://www.covenant.edu/pdf/visit/campus_map.pdf" target="_blank" text>
            <v-icon left>fas fa-map-marked</v-icon>
            Campus Map
          </v-btn>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-list>
              <v-list-item v-for="{ title, crn, meets, instructor, regStatus } in schedule" :key="'course-'+crn">
                <v-list-item-avatar v-if="regStatus === 'WL'">
                  <v-icon color="error">fas fa-exclamation-triangle</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ title }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <b style="vertical-align:top">Meets: </b>
                    <span v-html="meets" style="display:inline-block"></span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="instructor"><b>Instructor: </b>{{ instructor }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="regStatus === 'WL'" style="font-weight:bold">{{ isEmployee ? 'Student is' : 'You are' }} currently wait-listed for this course</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>Total credit hours: {{ credits }}</v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-tab-item>
          <v-tab-item>
            <v-calendar ref="calendar" type="week" :weekdays="[1,2,3,4,5,6]" :events="courseEvents" :first-interval="7" :interval-minutes="60" :interval-count="14" :interval-height="48" :short-weekdays="true" :start="calendarStart" :event-color="(event) => { return event.color }" @click:event="showCalendarCourseDetails" style="max-height:700px">
            </v-calendar>
            <v-menu v-model="eventMenuActive" :activator="selectedElement" offset-y>
              <v-card color="grey lighten-4" min-width="350px" max-width="500px" flat>
                <v-toolbar :color="selectedEvent.regStatus === 'WL' ? 'red' : 'primary'" dark>
                  <v-toolbar-title>{{ selectedEvent.title ? selectedEvent.title.split(' - ')[0] : '' }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <h3 v-if="selectedEvent.regStatus === 'WL'" style="margin-bottom:1em">You are currently waitlisted for this course.</h3>
                  <div v-if="selectedEvent.title && selectedEvent.title.split(' - ').length > 1">
                    <b class="mr-2">Title:</b>
                    <span style="display:inline-block">{{ selectedEvent.title.split(' - ')[1] }}</span>
                  </div>
                  <div>
                    <b class="mr-2" style="vertical-align:top;min-width:100px">Meets: </b>
                    <span v-html="selectedEvent.meets" class="ml-4" style="display:block"></span>
                  </div>
                  <div v-if="selectedEvent.instructor">
                    <b class="mr-2" style="vertical-align: top">Instructor: </b>
                    <span style="display:inline-block">{{ selectedEvent.instructor ? selectedEvent.instructor : '' }}</span>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="eventMenuActive = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-tab-item>
        </v-tabs-items>
      </template>
      <template v-else>
        <v-toolbar>
          <v-spacer></v-spacer>
          <v-btn icon :disabled="workstudyCalendarDay === 'Sunday'" @click="prevDay">
            <v-icon>fal fa-chevron-left</v-icon>
          </v-btn>
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text>
                {{ workstudyCalendarDay }}
                <v-icon right>fal fa-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="({ text, value }, index) in workstudyCalendarDays" :key="'days-' + index" @click="workstudyCalendarDate = value">
                <v-list-item-title>{{ text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn icon :disabled="workstudyCalendarDay === 'Saturday'" @click="nextDay">
            <v-icon>fal fa-chevron-right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-calendar v-model="workstudyCalendarDate" ref="workstudyCalendar" type="category" category-show-all :categories="workstudyStudents" :events="workstudyEvents" :first-interval="7" :interval-minutes="60" :interval-count="14" :interval-height="48" :short-weekdays="true" :start="calendarStart" :event-color="(event) => { return event.color }" @click:event="showCalendarCourseDetails" style="max-height:700px"></v-calendar>
      </template>
    </v-card>
  </v-container>
</template>
<style>
.v-calendar.theme--dark.v-calendar-events .v-event-timed.primary {
  color: #1E1E1E !important
}
.v-calendar.theme--dark.v-calendar-events .v-event-timed.grey {
  color: black !important
}
.v-calendar .theme--dark.v-card.grey.lighten-4 {
  background-color: #1E1E1E !important;
  color: white;
}
.v-calendar .theme--dark.v-card.grey.lighten-4 .v-toolbar__title {
  color: #1E1E1E !important;
}
.v-calendar .v-calendar-daily.v-calendar-category .v-calendar-daily_head-day-label, .v-calendar-daily.v-calendar-category .v-calendar-daily_head-weekday {
  display: none;
}
.v-calendar .v-calendar-daily.v-calendar-category .v-calendar-category__column-header {
  padding: .3em 0;
}
.v-calendar .v-event-timed {
  white-space: pre-wrap;
}
.v-menu__content.theme--dark .v-card.theme--dark.grey.lighten-4 {
  background-color: #1E1E1E !important;
  color: white;
}
.v-menu__content.theme--dark .v-card.theme--dark .v-toolbar.theme--dark.primary {
  color: #1E1E1E !important;
}
</style>
<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const user = computed(() => 'spoof' in root.$store.state.user ? root.$store.state.user.spoof : root.$store.state.user)
    const id = computed(() => root.$route.params.id)
    const pidm = ref('')
    const term = ref('')
    const termOptions = ref([])
    const termText = computed(() => term.value === '' ? '' : termOptions.value.filter(({ value }) => value === term.value)[0].text)
    const noClasses = ref(false)
    const tab = ref(0)
    const credits = ref(0)
    const schedule = ref([])
    const eventMenuActive = ref(false)
    const selectedElement = ref(null)
    const selectedEvent = ref({})
    const buildings = ref({
      KRESGE: {
        name: 'Anna Emma Kresge Mem. Library',
        map: 'https://www.google.com/maps/place/Kresge+Memorial+Library/@34.9634823,-85.3752524,18z/data=!4m5!3m4!1s0x88605b478ef77be3:0xed6a0ba9c4d174ab!8m2!3d34.9638579!4d-85.3751582'
      },
      MILLS: {
        name: 'C G & Nancy Mills Science Bldg',
        map: 'https://www.google.com/maps/place/Mills+Hall,+Lookout+Mountain,+GA+30750/@34.9647086,-85.3766385,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b47804223ff:0x8519e45561689afc!8m2!3d34.9647064!4d-85.3755442'
      },
      SANDER: {
        name: 'Sanderson Hall',
        map: 'https://www.google.com/maps/place/Sanderson+Hall,+Lookout+Mountain,+GA+30750/@34.9633153,-85.3761544,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b47f29b9275:0xc6b6e337e0c81230!8m2!3d34.9633131!4d-85.3750601'
      },
      BARNES: {
        name: 'Barnes Physical Education Ctr',
        map: 'https://www.google.com/maps/place/Barnes+Physical+Education+Center/@34.9616528,-85.3775971,18z/data=!4m5!3m4!1s0x88605b72721a53ed:0x31608065c8bf66d8!8m2!3d34.9616993!4d-85.3765871'
      },
      ASHE: {
        name: 'Martha Ashe Activity Center',
        map: 'https://www.google.com/maps/place/Ashe+Activity+Center,+Lookout+Mountain,+GA+30750/@34.961143,-85.3779088,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b37eca6bddd:0x1754461fe6321ef3!8m2!3d34.9611408!4d-85.3768145'
      },
      FINART: {
        name: 'Chapel - D.M.Brown Fine Arts',
        map: 'https://www.google.com/maps/place/Dora+Maclellan+Brown+Memorial+Chapel,+Lookout+Mountain,+GA+30750/@34.964451,-85.3756679,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b479c19d3a5:0x9842c8ed91a4516c!8m2!3d34.9644488!4d-85.3745736'
      },
      JACKSN: {
        name: 'Jackson Hall',
        map: 'https://www.google.com/maps/place/Jackson+Hall,+Lookout+Mountain,+GA+30750/@34.9640257,-85.3778891,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b38684effdd:0x2dc547236f1b46b2!8m2!3d34.9640235!4d-85.3767948'
      },
      ANDREA: {
        name: 'Andreas Hall',
        map: 'https://www.google.com/maps/place/Andreas+Residence+Hall,+Lookout+Mountain,+GA+30750/@34.9624482,-85.3770823,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b38040a1177:0x2eb8e0e67322ffd6!8m2!3d34.962446!4d-85.375988'
      },
      BROCK: {
        name: 'Brock Hall',
        map: 'https://www.google.com/maps/place/Brock+Hall,+Lookout+Mountain,+GA+30750/@34.9652147,-85.3753529,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b477332bd71:0x48d1ff9b8722c077!8m2!3d34.9652125!4d-85.3742586'
      },
      KIRK: {
        name: 'The Kirk',
        map: 'https://www.google.com/maps/place/The+Kirk+(Covenant+College+Alumni+Center)/@34.9597568,-85.3769352,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b7d950db32f:0x715ff8e33114e358!8m2!3d34.9597546!4d-85.3758409'
      },
      LUCAS: {
        name: 'Lucas Art Workshop',
        map: 'https://www.google.com/maps/place/Lucas+Art+Workshop/@34.9642745,-85.3789012,17z/data=!3m1!4b1!4m5!3m4!1s0x88605b43b8dce3cf:0x8df9f3b8e342b4bf!8m2!3d34.9642701!4d-85.3767125'
      },
      REMOTE: {
        name: 'Remote Learning'
      }
    })

    const isSmall = computed(() => {
      switch (root.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
      }
      return true
    })
    const courseEvents = computed(() => {
      const arr = []
      parseMeetings({ name: 'Chapel', color: 'grey', meets: 'MWF 11:00 - 11:35' }, arr)
      // events in the calendar need name, start, and end; start and end need to be ISO formatted (YYYY-MM-DD HH:MI:SS)
      for (const { title, meets, regStatus } of schedule.value) {
        const name = (regStatus === 'WL' ? '**Waitlisted** ' : '' + title)
        const color = regStatus === 'WL' ? 'red' : 'primary'
        parseMeetings({ name, color, meets }, arr)
      }
      return arr
    })
    const calendarStart = ref('2023-08-21')

    function showCalendarCourseDetails ({ nativeEvent, event }) {
      const open = () => {
        if (event.name === 'Chapel') {
          selectedEvent.value = {
            title: 'Chapel',
            meets: 'MWF 11:00am - 11:35am in CL110 (<a href="' + buildings.value.FINART.link + '" target="_blank">' + buildings.value.FINART.name + '</a>',
            regStatus: 'RE'
          }
        }
        let eventName = event.name
        if (event.name.substr(0, 15) === '**Waitlisted** ') {
          eventName = event.name.substr(15)
        }
        for (const row of schedule.value) {
          if (row.title === eventName) {
            selectedEvent.value = row
          }
        }
        selectedElement.value = nativeEvent.target
        setTimeout(() => { eventMenuActive.value = true }, 10)
      }

      if (eventMenuActive.value) {
        eventMenuActive.value = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    }

    const isEmployee = computed(() => {
      return 'roles' in user.value && Array.isArray(user.value.roles) && user.value.roles.includes('Employee')
    })
    const selectedStudent = ref('')
    watch(selectedStudent, (val) => {
      if (val != null && val !== '' && val !== id.value && /^@[0-9]{8}$/.test(val)) {
        root.$router.push('/calendar/schedule/' + val)
      }
    })
    function clearStudent () {
      selectedStudent.value = ''
      schedule.value = []
      root.$router.push('/calendar/schedule')
    }
    const isLoading = ref(false)

    const students = ref([])
    const studentSearch = ref('')
    watch(studentSearch, (val) => {
      if (val == null || val === '') {
        students.value = []
        return
      }
      let query = { person: 'Student', active: true, $limit: 20, $select: ['pidm', 'bannerId', 'name'] }
      let regex = /@[0-9]{1,8}/i
      if (regex.test(val)) {
        let match = val.match('@[0-9]{1,8}')[0]
        if (match === id.value) return
        query['bannerId'] = { $regex: match }
      } else if (val.search(',') >= 0) {
        let [last, first] = val.split(',')
        last = last.trim()
        first = first.trim()
        query['name.first'] = { $regex: first, $options: 'i' }
        query['name.last'] = { $regex: last, $options: 'i' }
      } else if (val.search(' ') > 0) {
        let [first, last] = val.trim().split(' ')
        if (first != null && first !== '') {
          query['name.first'] = { $regex: first, $options: 'i' }
        }
        if (last != null && last !== '') {
          query['name.last'] = { $regex: last, $options: 'i' }
        }
      } else {
        query.$or = [
          { 'name.first': { $regex: val.replace(' ', '[\\w ]'), $options: 'i' } },
          { 'name.last': { $regex: val.replace(' ', '[\\w ]'), $options: 'i' } }
        ]
      }
      root.$feathers.service('directory/people').find({ query }).then(({ data }) => {
        students.value = data.map(({ name: { first, last }, bannerId }) => ({ text: first + ' ' + last + ' (' + bannerId + ' )', value: bannerId }))
      })
    })

    watch(id, async () => {
      if (id.value != null && id.value !== '') {
        const { data } = await root.$feathers.service('directory/people').find({ query: { bannerId: id.value, $select: ['pidm', 'bannerId', 'name'] } })
        if (data.length > 0) {
          pidm.value = data[0].pidm
          loadSchedule()
        } else {
          noClasses.value = true
          schedule.value = []
          credits.value = 0
        }
      }
    })
    watch(pidm, async () => {
      if (pidm.value !== '') {
        // Load the terms for the current student
        const aggregate = [
          { $match: { 'students.pidm': pidm.value } },
          { $group: { _id: '$term', classes: { $sum: 1 } } },
          { $lookup: { from: 'Terms', localField: '_id', foreignField: 'term', as: 'term' } },
          { $project: { classes: 1, term: { $first: '$term' } } },
          { $project: { classes: 1, value: '$term.term', text: '$term.label', start: '$term.start' } },
          { $sort: { start: -1 } }
        ]
        const { data } = await root.$feathers.service('calendar/classes').find({ query: { aggregate } })
        termOptions.value = data
        // Check to see if the selected term exists for this student; if not, change the term to the latest one for this student
        if (data.length > 0 && data.filter(({ value }) => value === term.value).length === 0) {
          term.value = data[0].value
        }
      }
    })
    watch(term, () => {
      loadSchedule()
    })

    onMounted(async () => {
      // Check to see if the user is an employee; if so, we will check the passed Banner ID and load the schedule for that user
      if (isEmployee.value) {
        // Check to see if the user has access to a work-study list in the directory
        root.$feathers.service('directory/list').find({ query: { type: 'workstudy', $or: [{ owners: user.value.directoryId }, { 'share.user': user.value.directoryId }], $limit: 50 } }).then(({ data }) => {
          if (data.length > 0) {
            workstudyList.value = data.map(({ code, title, groups: [{ people }] }) => ({ code, title, people }))
          }
        })
        if (id.value != null && id.value !== '') {
          root.$feathers.service('directory/people').find({ query: { bannerId: id.value } }).then(({ data }) => {
            students.value = data.map(({ name: { first, last }, bannerId }) => ({ text: first + ' ' + last + ' (' + bannerId + ' )', value: bannerId }))
            selectedStudent.value = data[0].bannerId
          })
        }
      } else {
        pidm.value = user.value.pidm
      }
    })

    async function loadSchedule (returnEvents) {
      if (isLoading.value || term.value === '' || pidm.value === '') return
      isLoading.value = true
      const $match = { 'students.pidm': pidm.value, term: term.value }
      const aggregate = [
        { $match },
        { $unwind: '$students' },
        { $match },
        { $project: { crn: 1, title: 1, section: 1, credits: 1, instructor: { $first: '$instructors' }, meetingBase: 1, regStatus: '$students.regStatus' } },
        {
          $project: {
            crn: 1,
            title: 1,
            credits: 1,
            instructor: '$instructor.name',
            regStatus: 1,
            meets: {
              $reduce: {
                input: '$meetingBase',
                initialValue: '',
                in: {
                  $concat: [
                    '$$value',
                    { $cond: [{ $eq: ['$$value', ''] }, '', '<br/>'] },
                    { $cond: [{ $eq: ['$$this.days', ''] }, '', { $concat: ['$$this.days', ' '] }] },
                    { $cond: [{ $eq: ['$$this.startTime', ''] }, '', { $concat: ['$$this.startTime', ' - '] }] },
                    '$$this.endTime',
                    { $cond: [
                      { $eq: ['$$this.room', null] },
                      '',
                      { $concat: [' in ', '$$this.room', ' (', '$$this.building', ')'] }
                    ] }
                  ]
                }
              }
            }
          }
        },
        { $project: { crn: 1, title: 1, credits: 1, instructor: 1, regStatus: 1, meets: { $cond: [{ $eq: ['$meets', ''] }, '**No Meeting Times Specified**', '$meets'] } } }
      ]
      let { data } = await root.$feathers.service('calendar/classes').find({ query: { aggregate } })
      if (data.length === 0) {
        if (!returnEvents) noClasses.value = true
        else {
          isLoading.value = false
          return []
        }
      } else {
        noClasses.value = false
        let arr = data.map((row) => {
          for (const building in buildings.value) {
            const { name, map } = buildings.value[building]
            row.meets = row.meets.replaceAll(building, '<a href="' + map + '" target="_blank">' + name + '</a>')
          }
          return row
        })
        isLoading.value = false
        if (returnEvents) {
          return arr
        } else {
          schedule.value = arr
          credits.value = data.filter(({ regStatus }) => regStatus !== 'WL').reduce((prev, { credits }) => prev + credits, 0)
        }
      }
      isLoading.value = false
    }

    const selectedSchedule = ref('student')
    const selectedScheduleTitle = ref('Student Class Schedule')
    const workstudyList = ref([])

    async function setSelectedSchedule (code) {
      const colors = ['red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange', 'brown', 'blue-grey', 'grey']
      selectedSchedule.value = code
      if (code === 'student') {
        selectedScheduleTitle.value = 'Student Class Schedule'
      } else {
        workstudyStudents.value = []
        workstudyEvents.value = []
        const block = workstudyList.value.filter(({ code: wsCode }) => wsCode === code)
        if (block.length > 0) {
          selectedScheduleTitle.value = block[0].title
          const aggregate = [
            { $match: { _id: { $in: block[0].people } } },
            {
              $lookup: {
                from: 'Calendar-Classes',
                localField: 'pidm',
                foreignField: 'students.pidm',
                as: 'classes',
                let: { pidm: '$pidm' },
                pipeline: [
                  { $unwind: '$students' },
                  { $match: { term: '202360', $expr: { $eq: ['$students.pidm', '$$pidm'] } } },
                  {
                    $project: {
                      title: 1,
                      meets: {
                        $reduce: {
                          input: '$meetingBase',
                          initialValue: '',
                          in: {
                            $concat: [
                              '$$value',
                              { $cond: [{ $eq: ['$$value', ''] }, '', '<br/>'] },
                              { $cond: [{ $eq: ['$$this.days', ''] }, '', { $concat: ['$$this.days', ' '] }] },
                              { $cond: [{ $eq: ['$$this.startTime', ''] }, '', { $concat: ['$$this.startTime', ' - '] }] },
                              '$$this.endTime',
                              { $cond: [
                                { $eq: ['$$this.room', null] },
                                '',
                                { $concat: [' in ', '$$this.room', ' (', '$$this.building', ')'] }
                              ] }
                            ]
                          }
                        }
                      }
                    }
                  },
                  { $match: { meets: { $ne: '' } } }
                ]
              }
            },
            { $project: { classes: 1, name: 1, email: 1 } }
          ]
          const { data } = await root.$feathers.service('directory/people').find({ query: { aggregate } })
          // console.log(data)
          let colorIndex = 0
          const arr = []
          for (const { name: { first, last }, classes } of data) {
            const color = colors[colorIndex % colors.length] + (colorIndex > colors.length ? ' darken-' + Math.floor(colorIndex / colors.length) * 2 : '')
            const category = first + ' ' + last
            workstudyStudents.value.push(category)
            parseMeetings({ meets: 'MWF 11:00am - 11:35am', name: 'Chapel', color, category }, arr)
            for (const { meets, title } of classes) {
              // console.log({ meets, title })
              parseMeetings({ meets, name: title, color, category }, arr)
            }
            colorIndex++
          }
          // console.log(arr)
          workstudyEvents.value = arr
        }
      }
    }

    const workstudyCalendar = ref(null)
    const workstudyCalendarDays = ref([
      { text: 'Sunday', value: '2023-08-20' },
      { text: 'Monday', value: '2023-08-21' },
      { text: 'Tuesday', value: '2023-08-22' },
      { text: 'Wednesday', value: '2023-08-23' },
      { text: 'Thursday', value: '2023-08-24' },
      { text: 'Friday', value: '2023-08-25' },
      { text: 'Saturday', value: '2023-08-26' }
    ])
    const workstudyCalendarDate = ref(calendarStart.value)
    const workstudyCalendarDay = computed(() => {
      let dt = new Date(workstudyCalendarDate.value + ' 8:00')
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      return days[dt.getDay()]
    })
    const workstudyStudents = ref([])
    const workstudyEvents = ref([])

    function prevDay () {
      workstudyCalendar.value.prev()
    }
    function nextDay () {
      workstudyCalendar.value.next()
    }

    function parseMeetings ({ meets, name, color, category }, arr) {
      const weekDays = { M: 0, T: 1, W: 2, R: 3, F: 4, S: 5 }
      let [ year, month, day ] = calendarStart.value.split('-')
      day = parseInt(day)
      if (meets === '' || meets === '**No Meeting Times Specified**') {
        return
      }

      // console.log(meets)

      const temp = meets.split('<br/>')
      for (const time of temp) {
        const [days, startTime, endTime] = time.replace(' - ', ' ').split(' ')
        if (startTime && endTime) {
          let [startHour, startMin] = startTime.split(':')
          let [endHour, endMin] = endTime.split(':')
          startHour = parseInt(startHour)
          if (startMin.substring(2) === 'pm' && startHour !== 12) startHour += 12
          startMin = startMin.substring(0, 2)
          const start = (startHour < 10 ? '0' : '') + startHour + ':' + startMin
          endHour = parseInt(endHour)
          if (endMin.substring(2) === 'pm' && endHour !== 12) endHour += 12
          endMin = endMin.substring(0, 2)
          const end = (endHour < 10 ? '0' : '') + endHour + ':' + endMin

          for (let k = 0; k < days.length; k++) {
            // console.log({
            //   start: year + '-' + month + '-' + (day + weekDays[days[k]]) + ' ' + start,
            //   end: year + '-' + month + '-' + (day + weekDays[days[k]]) + ' ' + end
            // })
            arr.push({
              name,
              start: year + '-' + month + '-' + (day + weekDays[days[k]]) + ' ' + start,
              end: year + '-' + month + '-' + (day + weekDays[days[k]]) + ' ' + end,
              color,
              category
            })
          }
        }
      }
    }

    return {
      user,
      id,
      pidm,
      term,
      termText,
      termOptions,
      noClasses,
      tab,
      credits,
      schedule,
      eventMenuActive,
      selectedElement,
      selectedEvent,
      buildings,
      isSmall,
      courseEvents,
      calendarStart,
      showCalendarCourseDetails,
      isEmployee,
      selectedStudent,
      clearStudent,
      students,
      studentSearch,
      selectedSchedule,
      selectedScheduleTitle,
      workstudyList,
      setSelectedSchedule,
      workstudyCalendar,
      workstudyCalendarDate,
      workstudyCalendarDays,
      workstudyCalendarDay,
      workstudyStudents,
      workstudyEvents,
      prevDay,
      nextDay
    }
  }
}
</script>
